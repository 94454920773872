import React from 'react';

const Label = (props) => {
  return (
    <div className="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
      <p>
        <label className={props.className}>{props.value}</label>
      </p>
    </div>
  );
}

export default Label;
