import React from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../context/app-context';
import { sortByInstance } from '../../helpers/sort';
import api from '../../services/api';
import Input from '../Input';
import Select from '../Select';

const Filter = () => {

  const { setDataProvider } = useAppContext()
  const [query, setQuery] = React.useState({
    region_code: 'ap-southeast-2'
  })
  const [regions, setRegions] = React.useState([])
  const [availabilityZones, setAvailabilityZones] = React.useState([])
  const [operatingSystems, setOperatingSystems] = React.useState([])
  const [form, setForm] = React.useState(() => ({
    region_code: 'ap-southeast-2',
    availability_zone: '',
    operating_system: '',
    vcpu: '',
    memory: '',
    submitted: false
  }))

  const loadOperatingSystems = React.useCallback(async () => {
    try {
      const { data: response } = await api.get('/operating-systems')
      const systems = response.data
      const osList = Object.keys(systems).reduce((acc, os) => {
        acc.push({
          value: os,
          label: systems[os]
        })
        return acc
      }, [])
      setOperatingSystems(osList)
    } catch (err) {}
  }, [])

  const loadRegion = React.useCallback(async () => {
    try {
      const { data: response } = await api.get('/regions')
      const regions = response.data
      const regionList = Object.keys(regions).reduce((acc, region) => {
        acc.push({
          value: regions[region],
          label: region
        })
        return acc
      }, [])
      setRegions(regionList)
    } catch (err) {}
  }, [])

  const loadAvailabilityZone = React.useCallback(async (region) => {
    const query = { region }
    let zonesList = []
    try {
      const { data: response } = await api.get('/availability-zones', { params: query })
      const availabilityZone = response.data
      zonesList = availabilityZone.reduce((acc, zone) => {
        acc.push({
          value: zone.ZoneName,
          label: zone.ZoneName
        })
        return acc
      }, [])
    } catch (err) {
      toast(`Region "${region}" does not have an availability zone.`, { type: 'warning' })
    }
    
    setAvailabilityZones(zonesList)
  }, [])

  const resetQueryFilterAz = React.useCallback(() => {
    setQuery(state => {
      delete state.availability_zone
      return state
    })
  }, [])

  React.useEffect(() => {
    loadOperatingSystems()
  },[loadOperatingSystems])

  React.useEffect(() => {
    loadRegion()
  },[loadRegion])

  React.useEffect(() => {
    if (form.region_code.length) {
      loadAvailabilityZone(form.region_code)
      resetQueryFilterAz()
    }
  },[form.region_code, loadAvailabilityZone, resetQueryFilterAz])

  const disableSubmitButton = value => {
    setForm(state => ({ ...state, submitted: value }))
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!query.hasOwnProperty('region_code')) {
      return toast(`Select a region.`, { type: 'warning' })
    } 

    disableSubmitButton(true)

    try {  
      const { data: response } = await api.get('/spots', { params: query })
      const result = response.data

      if (!result.length) {
        setDataProvider([])
        disableSubmitButton(false)
        return toast(`No results found.`, { type: 'info' })
      }

      setDataProvider(result.sort(sortByInstance))
    } catch (err) {
      console.error(err.message)
    }
    return disableSubmitButton(false)
  }

  const onChange = event => {
    const value = event.target.value
    const field = event.target.name

    setQuery(state => {
      if (value) return { ...state, [field]: value }
      delete state[field]
      return { ...state }
    })
    setForm(state => ({ ...state, [field]: value }))
  }

  return (
    <div className="w-full flex flex-col my-4 bg-white shadow rounded p-6">
      <form onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-6 gap-6">
          <Select
            label="Region"
            value={form.region_code}
            onChange={onChange}
            name="region_code"
            className="py-1 px-1 bg-white outline-none block h-full w-full"
            options={regions}
          />
          <Select
            label="AZ's"
            value={form.availability_zone}
            onChange={onChange}
            name="availability_zone"
            className="py-1 px-1 bg-white outline-none block h-full w-full"
            options={availabilityZones}
          />
          <Select
            label="O.S"
            value={form.operating_system}
            onChange={onChange}
            name="operating_system"
            className="py-1 px-1 bg-white outline-none block h-full w-full"
            options={operatingSystems}
          />
          <Input
            label="Min CPU"
            name="vcpu"
            value={form.vcpu}
            onChange={onChange}
            type="number"
            min="0"
            className="py-1 px-1 bg-white outline-none block h-full w-full" />
          <Input
            label="Min Memory"
            name="memory"
            value={form.memory}
            onChange={onChange}
            type="number"
            min="0"
            className="py-1 px-1 bg-white outline-none block h-full w-full" />
          <div className="relative p-1">
            <button 
              type="submit"
              disabled={form.submitted ? 'disabled' : ''}
              className={`${form.submitted ? 'opacity-50 cursor-not-allowed' : ''} rounded text-gray-100 px-6 w-full h-full py-1 bg-blue-500 hover:bg-blue-700`}>
                Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Filter;
