import React from 'react';

const Dashboard = () => {
  return (
    <div className="flex flex-col h-full w-full items-center text-center py-12">
      <h1 className="text-gray-400 font-bold text-shadow-lg text-6xl">SPOT FINDER</h1>
    </div>
  );
}

export default Dashboard;