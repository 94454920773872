import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Dashboard from './components/Dashboard'
import Filter from './components/Filter'
import Table from './components/Table'
import { useAppContext } from './context/app-context'
import { formatToCurrency } from './helpers/format'
import { sortByInstance } from './helpers/sort'
import Layout from './layout'
import api from './services/api'

const App = () => {

  const { dataProvider, setDataProvider } = useAppContext()
  const [cost, setCost] = React.useState('hourly')
  const hasItems = dataProvider.length
  
  const loadSpotInstances = async () => {
    try {
      const query = { region_code: 'ap-southeast-2' }
      const { data: response } = await api.get('/spots', { params: query })
      const result = response.data
      if (!result.length) {
        return toast(`No results found.`, { type: 'info' })
      }
      
      setDataProvider(result.sort(sortByInstance))
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    loadSpotInstances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    { attribute: 'instance_type', label: 'Instance Type' },
    { attribute: 'availability_zone', label: 'Availability Zone' },
    { attribute: 'memory', label: 'Memory (GiB)' },
    { attribute: 'vcpu', label: 'vCPUs' },
    { attribute: 'storage', label: 'Storage' },
    { attribute: 'operating_system', label: 'O.S' },
    { attribute: 'spot_price', label: 'Spot Price', value: (row) => {
        let spot_price = acceptedCost[cost](row.spot_price)
        return formatToCurrency(spot_price)
      }
    },
    { attribute: 'on_demand_price', label: 'On Demand Price', value: (row) => {
        let on_demand_price = acceptedCost[cost](row.on_demand_price)
        return formatToCurrency(on_demand_price)
      }
    },
    { attribute: 'discount', label: 'Discount', value: (row) => {
        return `${Math.round(row.discount)}%`
      }
    },
    { attribute: 'frequency_interruption', label: 'Frequency Interruption' }
  ]

  const acceptedCost = {
    hourly (value) {
      return value
    },
    monthly (value) {
      return parseFloat(value) * 24 * 30
    }
  }

  const sortDataProvider = (columnName, columnToggle) => {
    let sortedData = ""
    if (columnToggle) sortedData = dataProvider.reverse()
    else {
      sortedData = dataProvider.sort((a, b) => {
        if (parseInt(a[columnName])) {
          return a[columnName] - b[columnName]
        } else {
          if (a[columnName] < b[columnName]) return -1
          if (a[columnName] > b[columnName]) return 1
          return 0;
        }
      })
    }
    setDataProvider(() => [...sortedData])
  }

  return (
    <Layout>
      <div className="container mb-auto flex flex-wrap">    
        <ToastContainer />
        <Filter />
        {
          !hasItems && <Dashboard />
        }
        {
          !!hasItems && <>
            <div className="w-full flex flex-col bg-white shadow rounded p-6">
              
              <div className="w-full flex mb-2">
                <div className="flex-1 text-left">
                  <p className="float-left">Showing <strong>{hasItems}</strong> instance(s).</p>
                </div>
                <div className="flex-1 text-right">
                  Cost
                  <select 
                    name="cost" 
                    value={cost} 
                    className="bg-white border px-2 py-1 ml-2"
                    onChange={(event) => setCost(event.target.value)}
                    >
                      <option value="hourly">Hourly</option>
                      <option value="monthly">Monthly</option>
                  </select>
                </div>
              </div>
             
              <Table 
                className="table table-auto border-collapse w-full" 
                columns={columns} 
                dataProvider={dataProvider}
                sorting={sortDataProvider}
              />
            </div>
          </>
        }
      </div>
    </Layout>
  )
}

export default App
