import React from 'react';
import Label from '../Label';
import Option from '../Option';

const Select = (props) => {
  const { options, label, ...attributes } = props
  
  return (
    <div className="border relative rounded p-1">
      <Label className="bg-white text-gray-600 px-1" value={label} />
      <p>
        <select {...attributes}>
          <Option options={options} />
        </select>
      </p>
  </div>
  );
}

export default Select;
