import React from 'react'

const Cell = ({ label, value }) => {
  const renderLabel = labelName => {
    return (
      <span className="lg:hidden px-2 py-1 text-sm font-bold">
        { labelName }
      </span>
    )
  }
  return (
    <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
      { label && renderLabel(label) }
      { value }
    </td>
  )
}

export default Cell