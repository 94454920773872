import React from 'react';

const Header = () => {
  return (
    <nav className="w-full py-4 bg-dark shadow">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between">
        <h1 className="items-center justify-between font-bold text-xl text-white uppercase no-underline">SPOT FINDER</h1>
        <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block pr-4">
          <svg className="fill-current text-gray-300" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
      </div>
    </nav>
  );
}

export default Header;
