import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full mx-auto py-2 border-t border-gray-400">
        <p className="text-center text-gray-600">
            Developed by <a className="inline-block no-underline font-bold hover:text-blue-600 hover:underline py-1" target="blank" href="https://dnx.solutions/">DNX Solutions</a>
        </p>
    </footer>
  );
}

export default Footer;
