import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { AppProvider } from './context/app-context'
import './styles.css'

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root')
)
