import React from 'react'

const Row = ({ index, cols }) => {
  const isEven = index % 2 === 0
  const className = isEven ? 'bg-gray-100' : ''
  
  return (
    <tr key={index}
      className={`bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 ${className}`}>
      { cols }
    </tr>
  )
}

export default Row
