import React from 'react';

export const AppContext = React.createContext()
export const AppProvider = ({ children }) => {

  const [dataProvider, setDataProvider] = React.useState([])

  return (
    <AppContext.Provider value={{ dataProvider, setDataProvider }}>
      { children }
    </AppContext.Provider>
  )
}

export const useAppContext = () => React.useContext(AppContext)
