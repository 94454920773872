import React from 'react';
import Label from '../Label';

const Input = (props) => {
  const { label, ...attributes } = props 
  return (
    <div className="border relative rounded p-1">
      <Label className="bg-white text-gray-600 px-1" value={label} />
      <input {...attributes} />
    </div>
  );
}

export default Input;
