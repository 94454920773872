import React from 'react'
import Cell from './table-cell'
import Row from './table-row'

const Table = ({ columns = [], dataProvider = [], sorting }) => {
 
  const [columnToggle, setColumnToggle] = React.useState({})

  const dispatch = (columnName) => {
    return (fn) => {
      if(typeof fn === 'function') {
       setColumnToggle(state => ({[columnName]: !state[columnName]}))
       return fn(columnName, columnToggle[columnName])
      }
      return false
    } 
  }

  const renderTableRow = (row, key) => {
    const isClosure = variable => typeof variable === "function"
    const rows = columns.map((column, index, grid) => {
      const { label, format, attribute, value } = column
      let cellValue = row[attribute] || null
      if (isClosure(format)) cellValue = format(cellValue)
      if (isClosure(value)) cellValue = value(row, key, index, grid)
      return <Cell key={`${key}-${index}`} label={label} value={cellValue} />
    })
    
    return rows
  }

  return (
    <table className="table table-auto border-collapse w-full">
      <thead>
        <tr>
          {
            columns.map((column, index) => (
              <td
                onClick={() => dispatch(column.attribute)(sorting)}
                key={index}
                className="border hover:text-blue-500 font-bold text-center px-4 py-2 hidden lg:table-cell cursor-pointer">
                  {column.label}
              </td>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          dataProvider.map((item, index) => {
            const rowCellData = renderTableRow(item, index)
            return <Row key={index} index={index} cols={rowCellData} />
          })
        }
      </tbody>
    </table>
  );
}

export default Table;