import React from 'react';

const Option = ({ options=[] }) => {
  return (
    <>
      <option value="">All</option>
      { options.map((item, index) => <option key={index} value={item.value}>{ item.label }</option>) }
    </>
  )
}

export default Option;
